<template>
  <div class="relative">
    <label :for="id">
      {{ label }}
      {{ required ? '*' : '' }}
    </label>
    <input
      v-if="!textarea"
      :id="id"
      v-model="value"
      :class="{ 'pr-16': unit }"
      :required="required"
      :type="type"
      :readonly="readonly"
      :min="min"
      :max="max"
      :step="step"
      :maxlength="maxlength"
      :minlength="minlength"
      :disabled="disabled"
      :placeholder="placeholder"
    >
    <textarea
      v-else
      :id="id"
      v-model="value"
      :rows="rows"
      :required="required"
      :disabled="disabled"
      :placeholder="placeholder"
    />
    <span v-if="unit" class="unit" v-text="unit" />
    <small v-if="error" class="text-red" v-text="te(error) ? t(error, [label]) : error" />
  </div>
</template>

<script>
import slugify from 'slugify';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'FancyInput',
  props: {
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    minlength: {
      type: Number,
      default: null,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    multiplier: {
      type: Number,
      default: null,
    },
    step: {
      type: Number,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 4,
    },
    unit: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { t, te } = useI18n();
    const id = slugify(props.label);
    const value = computed({
      get: () => {
        if (props.multiplier !== null) {
          return props.modelValue * props.multiplier;
        }
        return props.modelValue;
      },
      set: (val) => {
        let newVal = val;
        if (props.multiplier !== null) {
          newVal = val / props.multiplier;
        }
        if (props.type === 'number') {
          newVal = props.step === 1 ? parseInt(val, 10) : parseFloat(val);
        }
        emit('update:modelValue', newVal);
      },
    });

    return {
      t,
      te,
      id,
      value,
    };
  },
};
</script>

<style lang="scss" scoped>
.unit {
  @apply absolute right-0 bottom-0 w-14 rounded border border-secondary bg-gray-100;
  @apply text-sm text-gray-500 flex items-center justify-center;

  bottom: 0;
  top: calc(1rem + 2px);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}
textarea {
  height: auto;
}
</style>
