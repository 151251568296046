<template>
  <modal :open="state.open" @close="closeModal">
    <h2 v-if="addSaveLabel" class="mt-0">
      <template v-if="entity['@id']">
        {{ $t('general.action.editSth', [entity[nameProp] ?? options.label]) }}
      </template>
      <template v-else>
        {{ $t('general.action.createSth', [options.label]) }}
      </template>
    </h2>
    <h2 v-else class="mt-0">
      {{ options.label }}
    </h2>
    <hr>
    <form @submit.prevent="save(properties, entity)" :autocomplete="autocomplete">
      <slot :properties="properties" :errors="errors" :entity="entity" />

      <form-buttons
        :loading-submit="loading"
        :confirm-text="entity['@id'] ? $t('general.action.saveSth', [entity.name]) : addSaveLabel ? $t('general.action.createSth', [options.label]) : options.label"
        @cancel="closeModal"
      >
        <template #additional>
          <slot name="additionalButtons" :entity="entity" :properties="properties" />
        </template>
      </form-buttons>
    </form>
  </modal>
</template>

<script>
import {
  computed,
  provide, ref,
  toRef,
  watch, watchEffect,
} from 'vue';
import { cloneDeep } from 'lodash';
import { notify } from '@kyvg/vue3-notification';
import { useStore } from 'vuex';
import Modal from '@/components/utils/Modal.vue';
import FormButtons from '@/components/utils/forms/FormButtons.vue';
import axios from '@/services/axios';

export default {
  name: 'ModalForm',
  components: {
    Modal,
    FormButtons,
  },
  props: {
    type: { type: String, required: true },
    nameProp: { type: String, default: 'name' },
    options: { type: Object, default: () => ({}) },
    normalizer: { type: Function, default: null },
    saveFunc: { type: Function, default: null },
    autocomplete: { type: String, default: 'on' },
    addSaveLabel: { type: Boolean, default: true },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const store = useStore();
    const entity = computed(() => store.state.modals.entity);
    const options = toRef(props, 'options');
    const state = ref({ open: false });
    const loading = ref(false);
    const properties = ref(options.value.properties);
    const errors = ref({});

    const closeModal = () => {
      store.dispatch('modals/closeModal', props.type);
      errors.value = {};
    };

    const simpleSave = async (newValue, originalValue) => {
      loading.value = true;
      errors.value = {};
      const normalizedProperties = props.normalizer ? props.normalizer(newValue) : newValue;

      try {
        if (originalValue['@id']) {
          await axios.put(originalValue['@id'], normalizedProperties);
          notify(`${options.value.label} aktualisiert`);
        } else {
          await axios.post(options.value.postUri, normalizedProperties);
          notify(`${options.value.label} erstellt`);
        }
        closeModal();
        emit('update');
      } catch (err) {
        if (err.response?.status === 422 && err.response?.data?.violations != null) {
          err.response.data.violations.forEach((v) => {
            if (v.propertyPath && v.message) {
              errors.value[v.propertyPath] = v.message;
            }
          });
          notify({
            title: 'Ungültige Daten',
            type: 'warn',
          });
        } else {
          console.error(err, err.message, err.response);
          notify({
            title: 'Fehler beim Speichern',
            text: err?.response?.data?.['hydra:description'] ?? null,
            type: 'error',
          });
        }
      }
      loading.value = false;
    };

    const save = async (newValue, originalValue) => {
      if (props.saveFunc) {
        await props.saveFunc(newValue, originalValue, errors.value, simpleSave);
      } else {
        await simpleSave(newValue, originalValue);
      }
    };

    watchEffect(() => {
      if (state.value.open) {
        Object.keys(options.value.properties).forEach((p) => {
          properties.value[p] = cloneDeep(entity.value[p]);
        });
      } else {
        properties.value = {};
      }
    });

    watch(() => store.state.modals[props.type]?.open, (val) => {
      state.value.open = val;
    });

    provide('formData', { properties, errors });

    return {
      entity,
      properties,
      loading,
      state,
      errors,
      simpleSave,
      save,
      closeModal,
    };
  },
};
</script>
