<template>
  <slot name="action" :state="state" />

  <teleport to="#popup">
    <transition name="appear">
      <div v-if="state.open" class="Modal">
        <div
          class="backdrop"
          @click="
            state.open = false;
            $emit('close');
          "
        />

        <div class="box">
          <div class="content">
            <a
              @click="
                state.open = false;
                $emit('close');
              "
            ><Close
              class="close"
            /></a>
            <slot />
          </div>
          <slot name="footer" :state="state" />
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { ref, watch, watchEffect } from 'vue';
import Close from '@/assets/images/close.svg';

export default {
  components: { Close },
  props: { open: Boolean },
  emits: ['close'],
  setup(props, { emit }) {
    const state = ref({ open: false });

    watch(() => props.open, (val) => {
      state.value.open = val;
      if (!val) {
        emit('close');
      }
    });

    return {
      state,
    };
  },
  beforeUnmount() {
    this.$emit('close');
  },
};
</script>

<style lang="scss" scoped>
.Modal {
  position: fixed;
  z-index: 4000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(3px);
}
.backdrop {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  max-width: 100%;
  overflow: hidden;
  background: white;
  color: black;

  @screen sm {
    border-radius: 5px;
    width: 640px;
  }

  @screen md {
    width: 920px;
  }
}
.content {
  position: relative;
  padding: 30px;
  height: 100vh;
  max-width: 100%;
  overflow-y: auto;

  @screen md {
    height: 80vh;
  }
}
.close {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 3rem;
  padding: 1rem;
  cursor: pointer;
}
</style>
