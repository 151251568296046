import { onMounted, ref, watch } from 'vue';
import { merge, pickBy } from 'lodash';
import { useRouter } from 'vue-router';
import axios from '@/services/axios';

export default function useLoadEntities(endpoint, {
  fields, sort, direction, normalizer, defaultParams = {}, loadImmediately = true, itemsPerPage = 15, hideDeleted = true,
} = {}) {
  const router = useRouter();
  const loadingEntities = ref(false);
  const totalItems = ref(0);
  const entities = ref([]);
  const currentPage = ref(1);
  const filters = ref({});

  const sortBy = ref(sort ?? '');
  const sortDirection = ref(direction ?? 'asc');

  const initFilters = () => {
    if (fields) {
      fields.value.forEach((field) => {
        if (field.searchFilter) {
          filters.value[field.key] = field.searchFilter.default ?? '';
        }
      });
    }
  };
  initFilters();

  const loadEntities = async () => {
    loadingEntities.value = true;
    try {
      const params = {
        ...defaultParams,
        page: currentPage.value,
        itemsPerPage,
        ...(pickBy(filters.value, (v) => v)),
      };

      if (Array.isArray(sortBy.value)) {
        for (let i = 0; i < sortBy.value.length; i += 1) {
          params[`order[${sortBy.value[i]}]`] = Array.isArray(sortDirection.value) ? (sortDirection.value[i] ?? 'asc') : sortDirection.value;
        }
      } else if (sortBy.value && sortDirection.value) {
        params[`order[${sortBy.value}]`] = sortDirection.value;
      }

      if (hideDeleted) {
        params.hideDeleted = hideDeleted;
      }

      const { data } = await axios.get(endpoint, {
        params,
      });
      if (data['hydra:member']) {
        entities.value = data['hydra:member'].map((entity) => (normalizer ? normalizer(entity) : entity));
      }
      if (data['hydra:totalItems'] != null) {
        totalItems.value = data['hydra:totalItems'];
      }
    } catch (err) {
      console.error(err, err.message, err.response);
    }
    loadingEntities.value = false;
  };

  const updateFilters = async (newFilterValue) => {
    const query = { ...router.currentRoute.value.query };
    filters.value = merge(filters.value, newFilterValue);

    Object.keys(filters.value).forEach((filterName) => {
      const filterValue = filters.value[filterName];
      if (filterValue || filterValue === 0) {
        query[filterName] = filterValue;
      } else {
        delete query[filterName];
      }
    });

    await loadEntities();
    await router.push({ query: { ...query } });
  };

  const resetFilters = () => {
    filters.value = {};
    router.push({ query: {} });
    initFilters();
  };

  onMounted(async () => {
    const query = { ...router.currentRoute.value.query };

    if (Object.keys(query).length) {
      Object.keys(query).forEach((k) => {
        const f = {};
        f[k] = query[k];
        filters.value = merge(filters.value, f);
      });
    }

    if (loadImmediately) {
      await loadEntities();
    }
  });

  return {
    loadingEntities,
    entities,
    filters,
    totalItems,
    currentPage,
    sortBy,
    sortDirection,
    loadEntities,
    updateFilters,
    resetFilters,
  };
}
