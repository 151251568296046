<template>
  <div class="flex flex-col items-end sm:flex-row justify-between sm:items-center mt-8">
    <slot name="additional" />
    <button class="mb-4 white sm:mb-0 sm:mr-4" type="button" @click="$emit('cancel')">
      {{ cancelText ? cancelText : t('general.action.cancel') }}
    </button>
    <button :disabled="loadingSubmit" :class="{ red: deleteButton }" type="submit" @click="$emit('submit')">
      <spinner v-if="loadingSubmit" />
      <template v-else>
        {{ confirmText ? confirmText : t('general.action.ok') }}
      </template>
    </button>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import Spinner from '@/components/utils/spinner.vue';

export default {
  name: 'FormButtons',
  components: { Spinner },
  props: {
    cancelText: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: '',
    },
    deleteButton: {
      type: Boolean,
      default: false,
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['cancel', 'submit'],
  setup() {
    const { t } = useI18n();

    return { t };
  },
};
</script>
